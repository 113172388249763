import { graphql, PageProps } from "gatsby";
import React from "react";
import Layout, { marginPercent, maxWidth, breakpoint, MainContainerDiv, PageTitle } from "../components/Layout";
import { Videos } from "./__generated__/Videos";
import styled from "styled-components";
import { YouTubeLink } from "../utils/YouTubeLink";
import { useWindowSize } from "../hooks/useWindowSize";

interface IVideoWrapper {
	width: number;
	height: number;
}

const VideosWrapper = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
`;

const VideoFrame = styled.iframe`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: none;
`;

const VideoWrapper = styled.div<IVideoWrapper>`
	position: relative;
	margin-bottom: 20px;
	width: ${props => props.width}px;
	height: ${props => props.height}px;
`;

export default (props: PageProps<Videos>) => {

	const { containerWidth } = useWindowSize(maxWidth, marginPercent);
	const videoWidth = (containerWidth > breakpoint) ? containerWidth / 2 : containerWidth;
	const videoHeight = videoWidth * 9 / 16;
	const videos = props.data.markdownRemark!.frontmatter!.videos!;

	const getVideoId = (url: string) => {
		const link = YouTubeLink.parse(url);
		return link.id;
	};

	return (
		<Layout title="Videos">
			<MainContainerDiv>
				<PageTitle>Videos</PageTitle>
			</MainContainerDiv>

			<VideosWrapper>
				{videos.map((video) => <VideoWrapper key={video!.name!} width={videoWidth} height={videoHeight}>
					<VideoFrame src={`https://www.youtube.com/embed/${getVideoId(video!.url!)}`} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen={true} />
				</VideoWrapper>)}
			</VideosWrapper>
		</Layout>
	);
};

export const pageQuery = graphql`
	query Videos {
		markdownRemark(fileAbsolutePath: {regex: "/data\\/videos\\.*md/"}) {
			frontmatter {
				videos {
					name
					url
				}
			}
		}
	}
`;
