export class YouTubeLink {
	public id: string;

	constructor(id: string) {
		this.id = id;
	}

	public getEmbedLink(): string {
		return `https://www.youtube.com/embed/${this.id}`;
	}

	public static parse(youTubeUrl: string): YouTubeLink {

		const url = new URL(youTubeUrl);

		switch (url.hostname) {
			case "youtu.be":
				return new YouTubeLink(url.pathname.substr(1));
			case "www.youtube.com":
				const param = url.searchParams.get("v");
				if (!param) {
					throw new Error("Bad URL given");
				}
				return new YouTubeLink(param);
		}
		throw new Error("Bad URL given");
	}
}